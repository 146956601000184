import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices/index';
import { isPC, isPhone } from '@/common/utils';
import { namespace } from 'vuex-class';
import { service } from '@cloudpivot-hermes/nail-free-login';
const Global = namespace('global');
let ComponentName = class ComponentName extends Vue {
    get IsDingTalk() {
        return sessionStorage.IS_DINGTALK === 'true';
    }
    get isFreeLoginPlatform() {
        return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
    }
    get ISWECHAT() {
        return sessionStorage.IS_WECHAT === 'true';
    }
    /**
     * 跳转待办详情
     */
    clickHandler(item) {
        // htttp://xxxxx/#/appCode/appVersion?groupCode=1&moduleCode=1&pageGroupCode=1&pageCode=1&workItemId=111&workInstanceId=111
        // pageJson获取groupCode=1&moduleCode=1&pageGroupCode=1&pageCode=1
        // {
        //   workItemId:id,
        //   workInstanceId:instanceId,
        // }
        const params = {
            appCode: item.appCode,
            appVersion: item.appVersion,
        };
        const winOpen = !this.isFreeLoginPlatform && window.open('', '_blank');
        WorkPlatformService.getAppUrl(params).then((res) => {
            if (res.success) {
                let pageArr = [];
                try {
                    pageArr =
                        typeof item.pageJson === 'string' ? JSON.parse(item.pageJson) : [];
                }
                catch (e) { }
                let pageJson;
                for (const page of pageArr) {
                    // pageType:ALL,PC,MOBILE
                    if (page.pageType !== 'ALL') {
                        if (isPC() && page.pageType === 'PC') {
                            pageJson = page;
                            break;
                        }
                        else if (isPhone() && page.pageType === 'MOBILE') {
                            pageJson = page;
                            break;
                        }
                        else {
                            pageJson = page;
                        }
                    }
                    else {
                        pageJson = page;
                        break;
                    }
                }
                const query = {
                    groupCode: '',
                    pageGroupCode: '',
                    pageCode: '',
                    workItemId: item.id,
                    workflowInstanceId: item.instanceId,
                    appVersion: item.appVersion,
                    workflowCode: item.workflowCode,
                    ...pageJson,
                };
                const str = Object.entries(query)
                    .map((queryArr) => {
                    return queryArr.join('=');
                })
                    .join('&');
                let url = `${res.data}?${str}`;
                if (process.env.NODE_ENV !== 'production') {
                    url = `http://localhost${res.data.substring(res.data.indexOf('/api/launcher/'))}?${str}`;
                }
                try {
                    if (this.ISWECHAT && isPhone()) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                    else if ((this.IsDingTalk || this.ISWECHAT)) {
                        const token = localStorage.getItem('token');
                        const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                        if (this.needOpenBrowser) {
                            service.openLink(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                        else {
                            window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                        }
                    }
                    else {
                        if (!winOpen) {
                            throw new Error('浏览器新开弹窗未开启');
                        }
                        setTimeout(() => {
                            winOpen.location = url;
                        }, 100);
                    }
                }
                catch (e) {
                    Vue.prototype.$errorcatcher.showMessagePopup({
                        title: '错误提示！',
                        message: [e, '!'],
                        displayFooter: false,
                        correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
                    });
                }
                // window.location.href = url;
            }
        });
    }
    formatSeconds(value) {
        if (!value)
            return '--';
        let theTime = parseInt(value); // 需要转换的时间秒
        let theTime1 = 0; // 分
        let theTime2 = 0; // 小时
        let theTime3 = 0; // 天
        if (theTime > 60) {
            theTime1 = parseInt(String(theTime / 60));
            theTime = parseInt(String(theTime % 60));
            if (theTime1 > 60) {
                theTime2 = parseInt(String(theTime1 / 60));
                theTime1 = parseInt(String(theTime1 % 60));
                if (theTime2 > 24) {
                    // 大于24小时
                    theTime3 = parseInt(String(theTime2 / 24));
                    theTime2 = parseInt(String(theTime2 % 24));
                }
            }
        }
        let result = '';
        if (theTime > 0) {
            result = '' + parseInt(theTime + '') + '秒';
        }
        if (theTime1 > 0) {
            result = '' + parseInt(theTime1 + '') + '分' + result;
        }
        if (theTime2 > 0) {
            result = '' + parseInt(theTime2 + '') + '小时' + result;
        }
        if (theTime3 > 0) {
            result = '' + parseInt(theTime3 + '') + '天' + result;
        }
        return result;
    }
    timeOut(value) {
        const oldTime = new Date(value);
        const newTime = new Date();
        const timeout = parseInt((newTime - oldTime) + '');
        return `已超时${this.formatSeconds(timeout / 1000)}`;
    }
};
__decorate([
    Prop({ default: [] })
], ComponentName.prototype, "list", void 0);
__decorate([
    Global.State('needOpenBrowser')
], ComponentName.prototype, "needOpenBrowser", void 0);
ComponentName = __decorate([
    Component({
        name: 'todo-list',
    })
], ComponentName);
export default ComponentName;
